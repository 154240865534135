import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Button, Container, Stack, Typography, Link } from '@mui/material'
import OtpInput from 'react-otp-input'
import PasswordField from '../shared/atom/PasswordField'
import ValidationHint from '../shared/atom/MpValidationHint'
import { AuthLoader } from '../gateways/AuthLoader'
import { Logger } from '@meprism/app-utils'
import { minPasswordLength, validatePasswords } from './config'
import { Auth } from '@aws-amplify/auth'
import { submitForgotPassword } from '../../services/AuthenticationService'
import { useAppDispatch } from '../../redux/storeExports'
import { signInWithEmail } from '@meprism/shared/src/redux/authentication/authenticationSlice'
import MpTheme from '../../config/MpTheme'

export interface ChangePasswordRouteParam {
  username: string
}

const ChangePassword = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { state } = useLocation()
  const { username } = state as ChangePasswordRouteParam
  const email = username

  // Password states
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [shouldDisplayPasswordInfo, setShouldDisplayPasswordInfo] = useState(false)
  const [shouldShowMatchingPasswords, setShouldShowMatchingPasswords] = useState(false)

  // OTP states
  const [otp, setOtp] = useState('')
  const [errorText, setErrorText] = useState('')

  const { hasMatchingPassword, hasSymbol, hasSufficientLength, hasUpperCase } =
    validatePasswords(password, confirmPassword)
  const isPasswordValid =
    hasMatchingPassword && hasSymbol && hasSufficientLength && hasUpperCase

  const onPasswordReset = async () => {
    try {
      await submitForgotPassword(email, otp, password)
      await dispatch(signInWithEmail({ username: email, password })).unwrap()
      navigate('/changePasswordSuccess')
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'CodeMismatchException':
            setErrorText('The code you have entered is incorrect. Please try again')
            break
          case 'ExpiredCodeException':
            await Auth.forgotPassword(email)
            setErrorText(`The code you entered has expired. We have sent a new one to ${email}`)
            break
          case 'LimitExceededException':
            setErrorText('Unfortunately, you have exceeded the limit of failed attempts. Please wait 15 minutes and try again.')
            break
          default:
            setErrorText('An error occurred. Please try again later.')
            Logger.error(`Error in verifying OTP: ${error}`)
        }
      }
    }
  }

  const handleResendCode = async () => {
    try {
      await Auth.forgotPassword(email)
      setErrorText('A new verification code has been sent.')
    } catch (error) {
      Logger.error(`Error in resending code: ${error}`)
      setErrorText('Failed to resend code. Please try again.')
    }
  }

  return (
    <AuthLoader>
      <Container fixed maxWidth="sm">
        <Box
          mt="10px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center">
          
          {/* OTP Section */}
          <Stack spacing={2} sx={{ width: '100%', my: 2 }}>
            <Typography 
              variant="body2" 
              sx={{ 
                fontSize: '1rem', 
                textAlign: 'center',
                overflowWrap: 'break-word',
                wordWrap: 'break-word'
              }}
            >
              An account verification code has been sent to <strong>{email}</strong>
            </Typography>
          </Stack>
          <Stack maxWidth={MpTheme.layouts.widths.sm} spacing={4} sx={{ width: '100%', my: 4 }}>            
            <Box sx={{ width: '100%', justifyContent: 'space-between' }}>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputStyle={{
                  width: '16%',
                  height: '42px',
                  border: '2px solid #EFEDFDB2',
                  borderRadius: '10px',
                  margin: '0 4px'
                }}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </Box>

            <Link
              component="button"
              variant="body2"
              onClick={handleResendCode}
              sx={{ alignSelf: 'center', cursor: 'pointer' }}>
              Resend Code
            </Link>

            {errorText && (
              <Typography 
                color="error" 
                variant="body2"
                sx={{ 
                  mt: 2,
                  fontSize: '1rem',
                  textAlign: 'center',
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word'
                }}
              >
                {errorText}
              </Typography>
            )}
          </Stack>

          {/* Password Section */}
          <Stack
            maxWidth={MpTheme.layouts.widths.sm}
            spacing={4}
            sx={{ width: '100%', mt: 4 }}>
            <PasswordField
              fullWidth
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              onFocus={() => setShouldDisplayPasswordInfo(true)}
              InputLabelProps={{ shrink: true }}
              label="Password"
              variant="outlined"
              autoComplete="new-password"
            />
            <PasswordField
              fullWidth
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              onFocus={() => setShouldShowMatchingPasswords(true)}
              InputLabelProps={{ shrink: true }}
              label="Confirm Password"
              variant="outlined"
              autoComplete="new-password"
            />
            
            <Box sx={{ minHeight: 25 }}>
              {shouldDisplayPasswordInfo && (
                <Box>
                  <ValidationHint
                    isValid={hasSufficientLength}
                    text={`At least ${minPasswordLength} characters`}
                  />
                  <ValidationHint
                    isValid={hasUpperCase}
                    text="Contains at least one upper case letter"
                  />
                  <ValidationHint
                    isValid={hasSymbol}
                    text="Contains number or symbol"
                  />
                  {shouldShowMatchingPasswords && (
                    <ValidationHint
                      isValid={hasMatchingPassword}
                      text="Passwords match"
                    />
                  )}
                </Box>
              )}
            </Box>

            {/* Action Buttons */}
            <Button
              variant="contained"
              disabled={!isPasswordValid || otp.length !== 6}
              onClick={onPasswordReset}
              sx={{ mt: 2, minWidth: '180px' }}>
              Reset Password
            </Button>
            
            <Button
              variant="outlined"
              onClick={() => navigate(-1)}
              sx={{ mt: 1, mb: 2, minWidth: '180px' }}>
              Change Email
            </Button>
          </Stack>
        </Box>
      </Container>
    </AuthLoader>
  )
}

export default ChangePassword